import React, { useEffect, useState, Fragment } from 'react'
import { Button, WingBlank, InputItem, Flex, List, Toast, WhiteSpace } from 'antd-mobile'
import * as firebase from 'firebase/app'
import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie'
import { withRouter } from 'react-router-dom'
import { client } from '../../../apollo'
import { CHECK_USER } from '../graphql/Queries'
import { LOGIN } from '../graphql/Mutations';
import Meta from '../../../components/Meta'
import '../style/index.css'
import ikoverk from '../../../assets/lounge-logo.svg'

const cookies = new Cookies()

function Login(props) {
  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", { size: "invisible" });
  }, [])

  const [isLoading, setIsLoading] = useState(false)
  const [isOtpLoading, setIsOtpLoading] = useState(false)
  const [phone, setPhone] = useState(null)
  const [isOtp, setIsOtp] = useState(false)
  const [Otp, setOtp] = useState(null)
  const [sendingOtp, setSendingOtp] = useState(false)

  async function handleForm(e) {
    e.preventDefault();
    setIsLoading(true)
    if (!phone || phone.length !== 10) {
      setIsLoading(false)
      Toast.info('Please enter phone number properly!', 3, null, false)
    } else {
      client.query({ query: CHECK_USER, variables: { phone }, fetchPolicy: 'network-only' })
        .then((response) => {
          if (response && response.data && response.data.checkUser) {
            var phoneNumber = `+91${phone}`
            var appVerifier = window.recaptchaVerifier;
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
              .then(function (confirmationResult) {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                setIsOtp(true)
                setIsLoading(false)
              }).catch(function (error) {
                setIsLoading(false)
                // Error; SMS not sent
                console.log({ error })
                Toast.info('Failed to send SMS, please try again later', 4, null, false)
              });
          } else {
            setIsLoading(false)
            Toast.info('User does not exist. Please register first.', 4, null, false)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
            Toast.info(error.graphQLErrors[0].message, 3, null, false)
          } else {
            Toast.info(error.networkError.message, 3, null, false)
          }
        })
    }
  }

  function handleReSend() {
    setSendingOtp(true)
    var phoneNumber = `+91${phone}`
    var appVerifier = window.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        setSendingOtp(false)
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        Toast.info("OTP Re-Sent", 3, null, false)
      }).catch(function (error) {
        setIsLoading(false)
        setSendingOtp(false)
        // Error; SMS not sent
        console.log({ error })
        Toast.info('Failed to send SMS, please try again later', 4, null, false)
      });
  }

  function verifyCode() {
    setIsOtpLoading(true)
    if (!Otp || Otp.length !== 6) {
      setIsOtpLoading(false)
      Toast.info('Please enter valid OTP!', 3, null, false)
      return
    }
    if (Otp) {
      window.confirmationResult.confirm(Otp).then(function (result) {
        const phone = result.user.phoneNumber.replace('+91', '')
        client.mutate({ mutation: LOGIN, variables: { phone, password: "test@123" } })
          .then(response => {
            setIsOtpLoading(false)
            if (response.data.login && response.data.login.message) {
              cookies.get("connect.sid")
              const payload = jwt_decode(cookies.get("connect.sid"), { header: true })
              cookies.set('token', payload.passport.user.token, { maxAge: (48 * 60 * 1000) })
              Toast.success('Logged In Successfully!', 3, null, false);
              props.history.push('/')
            } else {
              Toast.fail('Login Failed!', 3, null, false)
            }
          }).catch(error => {
            setIsOtpLoading(false)
            console.log(error)
            Toast.fail('Login Failed!', 3, null, false)
          })
      }).catch(function (error) {
        setIsOtpLoading(false)
        // User couldn't sign in (bad verification code?)
        Toast.fail('Wrong verification code', 3, null, false)
      });
    }
  }

  return (
    <Fragment>
      <Meta title='Login' description='' />
      <Flex justify="center" style={{ paddingTop: '15vh' }}>
        <img src={ikoverk} alt='logo' width='60%' />
      </Flex>
      <Flex justify="center" style={{ paddingTop: '5vh', color: 'white' }}>
        <h2 style={{ fontWeight: '500' }}>WELCOME BACK</h2>
      </Flex>
      <WingBlank size='sm'>
        <WingBlank>
          <List renderHeader={() => 'Registered Phone Number'} className='input-list'>
            <InputItem
              type='digit'
              onChange={(e) => setPhone(e)}
              onKeyUp={(e) => {
                if ((e.keyCode === 13 || e.which === 13) && e.target.value.length === 10) {
                  handleForm(e)
                }
              }}
              maxLength={10}
              placeholder='Enter your phone number'
              disabled={isLoading || isOtp}
              value={phone}
            />
            {
              isOtp &&
              <InputItem
                type='digit'
                onChange={(e) => setOtp(e)}
                maxLength={10}
                placeholder='Enter your OTP'
                disabled={isOtpLoading}
                value={Otp}
                onKeyUp={(e) => {
                  if ((e.keyCode === 13 || e.which === 13) && e.target.value.length === 6) {
                    verifyCode(e)
                  }
                }}
              />
            }
          </List>
          <Flex justify='end'>
            {
              isOtp ?
                <p onClick={() => handleReSend()} style={{ color: '#888' }}>{sendingOtp ? 'Sending OTP...' : 'Resend OTP'}</p>
                :
                <p onClick={() => props.history.push('/register')} style={{ color: '#888' }}>Don't have an account? Register</p>
            }
          </Flex>
          <Flex justify="center" style={{ paddingTop: '12.5vh' }}>
            {
              isOtp ?
                <Button icon={isOtpLoading &&
                  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ shapeRendering: 'auto' }} width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" fill="none" stroke="#aeca36" strokeWidth="5" r="30" strokeDasharray="141.37166941154067 49.12388980384689" transform="rotate(2.84985 50 50)">
                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.8s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </circle>
                  </svg>
                }
                  className={`btn-inverted ${isOtpLoading ? 'btn-disabled' : ''}`} onClick={(e) => verifyCode(e)} >Verify</Button>
                :
                <button
                  id="sign-in-button"
                  type="button"
                  className={`btn am-button ${isLoading ? 'btn-disabled' : ''}`}
                  onClick={(e) => handleForm(e)}
                >
                  {isLoading &&
                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ shapeRendering: 'auto', margin: '10px' }} width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                      <circle cx="50" cy="50" fill="none" stroke="#ffffff" strokeWidth="5" r="30" strokeDasharray="141.37166941154067 49.12388980384689" transform="rotate(2.84985 50 50)">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.8s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                      </circle>
                    </svg>
                  }
                  Login
            </button>
            }
          </Flex>
          <WhiteSpace size='xl' />
        </WingBlank>
      </WingBlank>
    </Fragment>
  )
}

export default withRouter(Login)