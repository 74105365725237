import React, { useState, useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import { AppContext } from './AppContext'
import { client } from './apollo'
import { GET_CURRENT_USER } from './modules/profile/graphql/Queries';
import CheckIn from './modules/checkin/index'
import CheckOut from './modules/checkout/index'
import Plan from './modules/plan/index'
import EditProfile from './modules/profile/pages/EditProfile'
import Profile from './modules/profile/index'
import Transaction from './modules/transaction/index'
import Payment from './pages/payment'
import RestaurantDetails from './modules/restaurant/pages/RestaurantDetails';
import Home from './modules/home';
import Loader from './components/loader';
import Topup from './modules/topup';
import Refer from './pages/refer'
import HowItWorks from './modules/plan/components/HowItWorks';
import Support from './pages/support'
import Page404 from './components/page404';
import { Toast } from 'antd-mobile';

export default function (props) {
  const { dispatch, state } = useContext(AppContext)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (state && state.currentUser && state.currentUser.isActive === false) {
      Toast.info('User is disabled. Please contact admin!', 3, null, false)
      props.history.push('/logout')
    }
    if (state && state.currentUser && state.currentUser.phone)
      return
    client.query({ query: GET_CURRENT_USER, fetchPolicy: 'network-only' })
      .then(data => {
        if (!data.data.currentUser.isActive) {
          Toast.info('User is disabled. Please contact admin!', 3, null, false)
          props.history.push('/logout')
        }
        dispatch({ type: 'CURRENT_USER', data: data.data.currentUser })
        if (!data.data.currentUser.firstName || !data.data.currentUser.lastName || !data.data.currentUser.email) {
          props.history.push('/profile/new')
        }
        setLoading(false)
      })
      .catch(error => {
        console.log({ error })
        if (error && error.networkError && error.networkError.result && error.networkError.result.message) {
          Toast.info('User is disabled. Please contact admin!', 3, null, false)
          props.history.push('/logout')
        }
        setLoading(false)
      })
  }, [dispatch, state, props.history])

  return (
    loading ?
      <Loader />
      :
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/profile/edit' component={EditProfile} />
        <Route exact path='/profile/new' component={EditProfile} />
        <Route exact path='/profile' component={Profile} />
        <Route exact path='/plan' component={Plan} />
        <Route exact path='/restaurant/:id' component={RestaurantDetails} />
        <Route exact path='/transaction/:id' component={Transaction} />
        <Route exact path='/payment' component={Payment} />
        <Route exact path='/restaurant/:id/checkin' component={CheckIn} />
        <Route exact path='/restaurant/:id/checkout' component={CheckOut} />
        <Route exact path='/login' render={() => <Redirect to="/" />} />
        <Route exact path='/register' render={() => <Redirect to="/" />} />
        <Route exact path='/otp' render={() => <Redirect to="/" />} />
        <Route exact path='/topup' render={Topup} />
        <Route exact path='/how-it-works' component={HowItWorks} />
        <Route exact path='/support' render={Support} />
        <Route exact path='/refer' render={Refer} />
        <Route render={Page404} />
      </Switch>
    // TODO: create and add 404 page
  )
}