import React, { useContext, Fragment } from 'react';
import { Toast, Button, WhiteSpace, WingBlank, Flex, Modal } from 'antd-mobile';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment'
import { AppContext } from '../../../AppContext'
import { client } from '../../../apollo'
import { GET_CHECKED_IN } from '../../checkin/qraphql/Queries'
import { GET_RESTAURANT, } from '../graphql/Queries'
import { CHECK_IN } from '../graphql/Mutations'
import Meta from '../../../components/Meta';
import CustomCarousel from '../components/Carousel'
import Amenities from '../components/Amenities'
import Navbar from '../../../components/navbar'
import Schedule from '../components/Schedule'
import Loader from '../../../components/loader'
import Page404 from '../../../components/page404';
import placeHolderLogo from '../../../assets/restaurant-placeholder.jpg';
import marker from '../../../assets/marker.svg'

const alert = Modal.alert

const RestaurantDetails = (props) => {
  const { state } = useContext(AppContext)
  const user = state.currentUser
  const currentPoints = user.hasUpcomingPlan ? (user.points + user.userSubscription.lastOverDueAmount) : user.points
  const { match: { params: { id } } } = props
  const { loading, data } = useQuery(GET_RESTAURANT, { variables: { id: id }, fetchPolicy: 'network-only' });

  function checkIn() {
    Toast.info(<Loader />, 30)
    // TODO: remove unnecessary code or do comment if  needed in future
    try {
      client.query({
        query: GET_CHECKED_IN,
        variables: { id: user.id },
        fetchPolicy: "network-only"
      }).then(res => {
        if (res.data && res.data.checkIns.length === 0) {
          client.mutate({
            mutation: CHECK_IN,
            variables: {
              data: {
                user: { connect: { id: user.id } },
                restaurant: { connect: { id: id } }
              }
            }
          }).then(res => {
            Toast.hide()
            props.history.push(`${id}/checkin`)
          }).catch((e) => {
            Toast.hide()
            console.log(e)
            Toast.info('Something went wrong please try again later!', 5, null, false)
          });
        } else {
          Toast.hide()
          Toast.info("Please Checkout First Before Checking In Other Restaurants!", 5, null, false)
        }
      }).catch((error) => {
        Toast.hide()
        console.log(error)
        if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
          Toast.info(error.graphQLErrors[0].message, 3, null, false)
        } else {
          Toast.info(error, 3, null, false)
        }
      });
    }
    catch (error) {
      Toast.hide()
      console.log(error)
      if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
        Toast.info(error.graphQLErrors[0].message, 3, null, false)
      } else {
        Toast.info('Something went wrong!', 3, null, false)
      }
    }
  }

  const dayName = {
    "Sunday": 0,
    "Monday": 1,
    "Tuesday": 2,
    "Wednesday": 3,
    "Thursday": 4,
    "Friday": 5,
    "Saturday": 6
  };
  const today = dayName[moment().format('dddd')]
  const timingSlots = data && data.restaurant ? data.restaurant.restaurantTimings && data.restaurant.restaurantTimings.length > 0 && data.restaurant.restaurantTimings[today] ? data.restaurant.restaurantTimings[today].timingSlots : null : null
  const currentTime = moment().format('HH:mm')
  let slot1Start, slot2Start, slot1End, slot2End
  if (timingSlots) {
    slot1Start = timingSlots[0].startTime ? moment(timingSlots[0].startTime).format('HH:mm') : undefined
    slot2Start = timingSlots[1].startTime ? moment(timingSlots[1].startTime).format('HH:mm') : undefined
    slot1End = timingSlots[0].endTime ? moment(timingSlots[0].endTime).format('HH:mm') : undefined
    slot2End = timingSlots[1].endTime ? moment(timingSlots[1].endTime).format('HH:mm') : undefined
  }

  return (
    loading ?
      <Loader />
      :
      data && data.restaurant ?
        <div className='restaurant-detail-navbar' >
          <Navbar title={data.restaurant.name} backButton={true} >
            <Meta title={data.restaurant.name} description='Restaurant Details' />
            <WingBlank size='sm'>
              <WingBlank >
                <Flex wrap='wrap'>
                  <Flex.Item style={{ minWidth: '50px' }}>
                    <img src={data.restaurant.logo ? data.restaurant.logo : placeHolderLogo} alt='logo' style={{ height: '50px', width: '50px' }} />
                  </Flex.Item>
                  <Flex.Item className='title'>
                    <div className='name'>{data.restaurant.name}</div>
                    <div className='location'>{data.restaurant.address}</div>
                  </Flex.Item>
                  {
                    data.restaurant.mapUrl &&
                    <Flex.Item style={{ textAlign: 'center' }}>
                      <img
                        height='40px'
                        width='40px'
                        alt={data.restaurant.name}
                        src={marker}
                        onClick={() => (window.open(data.restaurant.mapUrl))}
                      />
                    </Flex.Item>
                  }
                </Flex>
                <WhiteSpace size='lg' />
                {
                  data.restaurant.images && data.restaurant.images.length > 0 ?
                    <CustomCarousel restaurantId={id} images={data.restaurant.images} /> : null
                }
                <WhiteSpace size='sm' />
              </WingBlank>
            </WingBlank>
            <Schedule data={data.restaurant.restaurantTimings} />
            <Amenities data={data.restaurant.Amenity} />
            {
              data.restaurant.minCheckinPoints && data.restaurant.minCheckinPoints !== 0 ?
                <Fragment>
                  <Flex className="schedule-title">
                    <WingBlank size="lg">
                      <div>Note</div>
                    </WingBlank>
                  </Flex>
                  <WingBlank size='lg'>
                    Once you Check-in, <b>{data.restaurant.minCheckinPoints} Points</b> will be liable to be deducted at the time of Checkout which will be reduced to Nil to the extent of order payment amount. Your account will be automatically Check-out at restaurant closing time.
                  </WingBlank>
                </Fragment> : null
            }
            <WhiteSpace size='xl' />
            <WhiteSpace size='xl' />
            <WhiteSpace size='xl' />
            <WhiteSpace size='sm' />
            <Flex justify="center" className='btn-floating'>
              {
                user.userSubscription && user.userSubscription.id ?
                  !(slot1Start && slot1End) && !(slot2Start && slot2End) ?
                    <Button className='btn isDisabled' >Closed Today</Button>
                    :
                    (slot1Start < currentTime && currentTime < slot1End) || (slot2Start < currentTime && currentTime < slot2End) ?
                      data.restaurant.minCheckinPoints > (user.topUp + currentPoints) ?
                        <Button className='btn isDisabled' onClick={() => { props.history.push(`/topup`) }}>Insufficient Balance</Button>
                        :
                        <Button onClick={
                          () => {
                            alert('Checkin', <>Are you sure you want to checkin at <b>{data.restaurant.name}</b>?</>, [
                              { text: 'Cancel' },
                              { text: 'Yes', onPress: () => checkIn() },
                            ])
                          }
                        } className='btn'>Check In</Button>
                      :
                      <Button className='btn isDisabled' >Closed</Button>
                  :
                  <Button onClick={() => { props.history.push(`/plan`) }} className='btn'>Choose plan to check in</Button>
              }
            </Flex>
          </Navbar>
        </div >
        :
        <Page404 />
  );
}

export default RestaurantDetails;