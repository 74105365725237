import React, { useEffect, useContext, useState, Fragment } from 'react'
import { Flex, WingBlank, WhiteSpace, InputItem, Button, Toast } from 'antd-mobile'
import { withRouter } from 'react-router-dom'
import { client } from '../../apollo'
import { AppContext } from '../../AppContext'
import { CREATE_TOPUP } from './graphql/Mutations'
import Navbar from '../../components/navbar'
import Meta from '../../components/Meta'
import './style/index.css'

function Topup(props) {
  const { history } = props
  const { state: { currentUser } } = useContext(AppContext)
  const [amount, setAmount] = useState(0)
  const currentPoints = currentUser.hasUpcomingPlan ? (currentUser.points + currentUser.userSubscription.lastOverDueAmount) : currentUser.points

  useEffect(() => {
    if (!currentUser.userSubscription) {
      Toast.fail("Please choose plan first", 2, null, false)
      setTimeout(() => history.replace('/'), 2000)
    }
  }, [history, currentUser.userSubscription])

  function handleTopup() {
    if (amount < 1) {
      Toast.info("Please enter valid amount.", 4, null, false)
    } else {
      const razorPayOptions = {
        "key": process.env.REACT_APP_RAZOR_PAY_KEY,
        "amount": (amount * 100), // 2000 paise = INR 20
        "name": `TOPUP - ikoverk Lounge`,
        "description": `These Points have a lifetime validity.`,
        "handler": (response) => {
          try {
            client.mutate({
              mutation: CREATE_TOPUP,
              variables: {
                data: {
                  user: { connect: { id: currentUser.id } },
                  topUpAmount: parseFloat(amount),
                  payment: {
                    create: {
                      user: { connect: { id: currentUser.id } },
                      subscription: { connect: { id: currentUser.userSubscription.id } },
                      amount: parseFloat(amount),
                      transactionId: response.razorpay_payment_id,
                      transactionStatus: "success",
                      transactionResponse: "",
                      createdBy: { connect: { id: currentUser.id } }
                    }
                  }
                }
              }
            }).then((res) => {
              setAmount(0)
              props.history.push("/payment")
            }).catch((error) => {
              console.log(error)
              if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
                Toast.info(error.graphQLErrors[0].message, 3, null, false)
              } else {
                Toast.info('Something went wrong!', 3, null, false)
              }
            })
          }
          catch (error) {
            console.log(error)
            if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
              Toast.info(error.graphQLErrors[0].message, 3, null, false)
            } else {
              Toast.info('Something went wrong!', 3, null, false)
            }
          }
        },
        "prefill": {
          "email": currentUser.email || '',
          "contact": currentUser.phone
        },
        "theme": {
          "color": "#aeca36"
        }
      }
      var razorPayRef = new window.Razorpay(razorPayOptions);
      razorPayRef.open();
    }
  }

  return (
    <Fragment>
      <Meta title='Topup' />
      <Navbar title='TOPUP' backButton={true}>
        <Flex className='current-points-header'>
          <Flex.Item>
            <WingBlank>Total Points</WingBlank>
          </Flex.Item>
          <Flex.Item>
            <WingBlank>
              <div className='align-right'>
                {`${currentPoints ? currentPoints : 0} Points`}
              </div>
            </WingBlank>
          </Flex.Item>
        </Flex>
        <WhiteSpace size='xl' />
        <WhiteSpace size='xl' />
        <WingBlank>
          <Flex style={{ fontSize: '1.2em' }}>
            How much do you want to add?
          </Flex>
          <WhiteSpace />
          <div className='amount-input'>
            <InputItem
              type='digit'
              onChange={(e) => parseInt(e) ? setAmount(parseInt(e)) : setAmount(0)}
              placeholder='Enter Amount'
              value={amount}
              onKeyUp={(e) => {
                if ((e.keyCode === 13 || e.which === 13) && e.target.value.length !== '') {
                  e.target.blur()
                }
              }}
            >
              <div style={{ color: '#fff', fontWeight: '500', fontSize: '1.2em' }}>₹</div>
            </InputItem>
          </div>
          <WhiteSpace />
          <WhiteSpace />
          <Flex justify='center' className='fixed-amount-flex'>
            <span className={`fixed-amount-block ${amount === 100 ? 'active' : ''}`} onClick={() => setAmount(100)}>+  <span className='price-symbol'>₹</span> 100 </span>
            <span className={`fixed-amount-block ${amount === 200 ? 'active' : ''}`} onClick={() => setAmount(200)}>+  <span className='price-symbol'>₹</span> 200 </span>
            <span className={`fixed-amount-block ${amount === 500 ? 'active' : ''}`} onClick={() => setAmount(500)}>+  <span className='price-symbol'>₹</span> 500 </span>
            <span className={`fixed-amount-block ${amount === 1000 ? 'active' : ''}`} onClick={() => setAmount(1000)}>+  <span className='price-symbol'>₹</span> 1000 </span>
          </Flex>
          <WhiteSpace size='xl' /><WhiteSpace size='xl' />
          <WhiteSpace size='xl' /><WhiteSpace size='xl' />
          <WhiteSpace size='xl' /><WhiteSpace size='xl' />
          <WhiteSpace size='xl' /><WhiteSpace size='xl' />
          <Flex justify='center' className='topup-note'>
            Note: These Points have a lifetime validity.
          </Flex>
          <WhiteSpace />
          <WhiteSpace />
          <Flex justify='center'>
            <Flex.Item>
              <Button className='btn btn-inverted' onClick={() => props.history.goBack()}>
                CANCEL
              </Button>
            </Flex.Item>
            <Flex.Item>
              <Button className='btn' onClick={() => handleTopup()}>
                ADD
              </Button>
            </Flex.Item>
          </Flex>
        </WingBlank>
      </Navbar>
    </Fragment>
  )
}

export default withRouter(Topup)