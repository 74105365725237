import React, { Fragment } from 'react';
import { Button, Flex, WingBlank, WhiteSpace } from 'antd-mobile';
import { withRouter } from 'react-router-dom'
import Meta from '../../components/Meta';
import './style/index.css'
import ikoverk from '../../assets/lounge-logo.svg'

const LandingPage = (props) => {

    return (<Fragment >
        <Meta title='iKoverk' description='' />
        <Flex justify="center" style={{ paddingTop: '15vh' }}>
            <img src={ikoverk} alt='logo' width='60%'></img>
        </Flex>
        <Flex justify="center" direction="column" style={{ paddingTop: '17.5vh' }} className='hero'>
            <Flex.Item>
                <div className='hero-description'>Work from<br /><span className='bold'>TOP Restaurants</span> in Surat.</div>
            </Flex.Item>
            <Flex.Item>
                <p className='how-it-works' onClick={() => props.history.push('/how-it-works')}>How it works</p>
            </Flex.Item>

        </Flex>
        <WingBlank>
            <Flex justify="center" style={{ paddingTop: '17.5vh' }}>
                <Button className='btn m-5' onClick={() => props.history.push("/login")}>
                    LOG IN
              </Button>
                <Button className='btn-inverted m-5' onClick={() => props.history.push("/register")}>
                    REGISTER
              </Button>
            </Flex>
            <WhiteSpace size='lg' />
            <WhiteSpace size='md' />
            <Flex justify="center">
                <div className='footer-description'>Book a desk at <a href='https://ikoverk.com/' className='ikoverk-text' target='_blank' rel='noopener noreferrer'>ikoverk</a></div>
            </Flex>
        </WingBlank>
    </Fragment>);
}

export default withRouter(LandingPage);