import React, { useState, useEffect, Fragment } from 'react'
import { Button, WingBlank, InputItem, Flex, Toast, List, } from 'antd-mobile'
import * as firebase from 'firebase/app'
import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie'
import { client } from '../../../apollo'
import { LOGIN, CREATE_USER, CREATE_REFERRAL } from '../graphql/Mutations';
import { CHECK_USER, CHECK_REFERRAL_CODE } from '../graphql/Queries'
import Meta from '../../../components/Meta'
import '../style/index.css'
import ikoverk from '../../../assets/lounge-logo.svg'
const cookies = new Cookies()

const Register = (props) => {
  const [phone, setPhone] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [isOtpLoading, setIsOtpLoading] = useState(false)
  const [isOtp, setIsOtp] = useState(false)
  const [Otp, setOtp] = useState(null)
  const [referralCode, setReferralCode] = useState(props.match.params.code ? props.match.params.code : "")
  const [sendingOtp, setSendingOtp] = useState(false)
  const [referralUserId, setReferralUserId] = useState(null)

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", { size: "invisible" });
  }, [])

  async function handleForm(e) {
    e.preventDefault();
    setIsLoading(true)
    if (!phone || phone.length !== 10) {
      setIsLoading(false)
      Toast.info('Please enter phone number correctly!', 3, null, false)
    } else {
      try {
        await checkReferralCode()
      }
      catch (error) {
        if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
          Toast.info(error.graphQLErrors[0].message, 3, null, false)
        } else {
          Toast.info(error, 3, null, false)
        }
        console.log(error)
      }
    }
  }

  async function checkReferralCode() {
    if (referralCode) {
      try {
        await client.query({
          query: CHECK_REFERRAL_CODE,
          variables: {
            referralCode: referralCode ? referralCode : "",
            fetchPolicy: 'network-only'
          }
        })
          .then(res => {
            // console.log({ data: res.data })
            // console.log(res && res.data && res.data.verifyReferralCode && res.data.verifyReferralCode.referralCode && res.data.verifyReferralCode.referralCode !== false)
            if (res && res.data && res.data.verifyReferralCode && res.data.verifyReferralCode.referralCode) {
              setReferralUserId(res.data.verifyReferralCode.id)
              checkUser()
            } else {
              setIsLoading(false)
              Toast.info('Invalid referral code.', 3, null, false)
              return
            }
          })
      } catch (error) {
        if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
          Toast.info(error.graphQLErrors[0].message, 3, null, false)
        } else {
          Toast.info(error, 3, null, false)
        }
        console.log(error)
      }
    } else {
      checkUser()
    }


  }

  function checkUser() {
    try {
      client.query({ query: CHECK_USER, variables: { phone }, fetchPolicy: 'network-only' })
        .then((response) => {
          if (response && response.data && !response.data.checkUser) {
            var phoneNumber = `+91${phone}`
            var appVerifier = window.recaptchaVerifier;
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
              .then(function (confirmationResult) {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                setIsLoading(false)
                setIsOtp(true)
              }).catch(function (error) {
                setIsLoading(false)
                // Error; SMS not sent
                console.log({ error })
                Toast.info('Failed to send SMS, please try again later', 4, null, false)
              })
          } else {
            setIsLoading(false)
            Toast.info('User already exist. Please login with OTP.', 3, null, false)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
            Toast.info(error.graphQLErrors[0].message, 3, null, false)
          } else {
            Toast.info(error, 3, null, false)
          }
        })
    }
    catch (error) {
      setIsLoading(false)
      if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
        Toast.info(error.graphQLErrors[0].message, 3, null, false)
      } else {
        Toast.info(error, 3, null, false)
      }
    }

  }

  function handleReSend() {
    setSendingOtp(true)
    var phoneNumber = `+91${phone}`
    var appVerifier = window.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        setSendingOtp(false)
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        Toast.info("OTP Re-Sent", 3, null, false)
      }).catch(function (error) {
        setIsLoading(false)
        setSendingOtp(false)
        // Error; SMS not sent
        console.log({ error })
        Toast.info('Failed to send SMS, please try again later', 4, null, false)
      });
  }

  function verifyCode() {
    setIsOtpLoading(true)
    if (!Otp || Otp.length !== 6) {
      setIsOtpLoading(false)
      Toast.info('Please enter valid OTP!', 3, null, false)
      return
    }
    if (Otp) {
      window.confirmationResult.confirm(Otp).then(function (result) {
        const phone = result.user.phoneNumber.replace('+91', '')
        client.mutate({
          mutation: CREATE_USER,
          variables: { data: { phone } },
          fetchPolicy: 'no-cache'
        }).then(async res => {
          if (res.data) {

            client.mutate({ mutation: LOGIN, variables: { phone, password: "test@123" } })
              .then(async response => {
                setIsOtpLoading(false)
                if (response.data.login && response.data.login.message) {
                  cookies.get("connect.sid")
                  const payload = jwt_decode(cookies.get("connect.sid"), { header: true })
                  cookies.set('token', payload.passport.user.token, { maxAge: (48 * 60 * 1000) })
                  Toast.success('Registered Successfully', 3, null, false);
                  if (referralUserId && referralCode) {
                    console.log('referral created')
                    await client.mutate({ mutation: CREATE_REFERRAL, variables: { userId: res.data.createUser.id, referralId: referralUserId } })
                  }
                  props.history.push('/')
                } else {
                  Toast.fail('Login Failed!', 3, null, false)
                }
              }).catch(error => {
                setIsOtpLoading(false)
                console.log(error)
                Toast.fail('Login Failed!', 3, null, false)
              })
          }
        }).catch(error => {
          setIsOtpLoading(false)
          console.log(error)
          Toast.fail('User already exist!', 3, null, false)
        })
      }).catch(function (error) {
        setIsOtpLoading(false)
        // User couldn't sign in (bad verification code?)
        Toast.fail('Invalid OTP', 3, null, false)
      });
    }
  }

  return (
    <Fragment>
      <Meta title='Register' description='' />
      <Flex justify="center" style={{ paddingTop: '15vh' }}>
        <img src={ikoverk} alt='logo' width='60%' />
      </Flex>
      <Flex justify="center" style={{ paddingTop: '5vh', color: 'white' }}>
        <h2 style={{ fontWeight: '500' }}>WELCOME</h2>
      </Flex>
      <WingBlank size='sm'>
        <WingBlank >
          <List renderHeader={() => 'Phone Number'} className='input-list'>
            <InputItem
              type='digit'
              maxLength={10}
              placeholder='Enter your phone number'
              onChange={(e) => setPhone(e)}
              disabled={isOtp || isLoading}
              onKeyUp={(e) => {
                if ((e.keyCode === 13 || e.which === 13) && e.target.value.length === 10) {
                  handleForm(e)
                }
              }}
            />
            {
              // <List renderHeader={() => 'Referral Code'} className='input-list' style={{ marginTop: '0' }}>
              !isOtp &&
              <InputItem
                type='text'
                // extra={
                //   referralCode ?
                //     referralCode.length === 7 ?
                //       <svg version="1.1" id="IconsRepoEditor" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 335.765 335.765" fill="#aeca36" stroke="#aeca36" stroke-width="0"><g id="IconsRepo_bgCarrier"></g> <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795 "></polygon> </svg>
                //       :
                //       <svg version="1.1" id="IconsRepoEditor" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 180.607 180.607" width="1px" height="1px" fill="#ff7171" stroke="#ff7171" stroke-width="0"><g id="IconsRepo_bgCarrier"></g> <path d="M180.607,10.607l-79.696,79.697l79.696,79.697L170,180.607l-79.696-79.696l-79.696,79.696L0,170.001l79.696-79.697L0,10.607 L10.607,0.001l79.696,79.696L170,0.001L180.607,10.607z"></path> </svg>
                //     :
                //     null
                // }
                // maxLength={7}
                // minLength={7}
                placeholder='Refferal code (optional)'
                onChange={(e) => setReferralCode(e)}
                // disabled={isOtp || isLoading}
                disabled={referralCode && (isOtp || isLoading)}
                value={referralCode}
                onKeyUp={(e) => {
                  if ((e.keyCode === 13 || e.which === 13) && e.target.value.length === 10) {
                    handleForm(e)
                  }
                }}
              />
              // </List>  
            }
            {
              isOtp &&
              <InputItem
                type='digit'
                onChange={(e) => setOtp(e)}
                maxLength={10}
                placeholder='Enter your OTP'
                disabled={isOtpLoading}
                value={Otp}
                onKeyUp={(e) => {
                  if ((e.keyCode === 13 || e.which === 13) && e.target.value.length === 6) {
                    verifyCode()
                  }
                }}
              />
            }
          </List>
          <Flex justify='end'>
            {
              isOtp ?
                <p onClick={() => handleReSend()} style={{ color: '#888' }}>{sendingOtp ? 'Sending OTP...' : 'Resend OTP'}</p>
                :
                <p onClick={() => props.history.push('/login')} style={{ color: '#888' }}>Already Have an Account? Login</p>
            }
          </Flex>
          <Flex justify="center" style={{ paddingTop: '5vh' }}>
            {
              isOtp ?
                <Button icon={isOtpLoading &&
                  <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ shapeRendering: 'auto' }} width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <circle cx="50" cy="50" fill="none" stroke="#aeca36" strokeWidth="5" r="30" strokeDasharray="141.37166941154067 49.12388980384689" transform="rotate(2.84985 50 50)">
                      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.8s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                    </circle>
                  </svg>
                }
                  className={`btn-inverted ${isOtpLoading ? 'btn-disabled' : ''}`} onClick={(e) => verifyCode(e)} >Verify</Button>
                :
                <button
                  id="sign-in-button"
                  type="button"
                  className={`btn am-button ${isLoading ? 'btn-disabled' : ''}`}
                  onClick={(e) => handleForm(e)}
                >
                  {isLoading &&
                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ shapeRendering: 'auto' }} width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                      <circle cx="50" cy="50" fill="none" stroke="#ffffff" strokeWidth="5" r="30" strokeDasharray="141.37166941154067 49.12388980384689" transform="rotate(2.84985 50 50)">
                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.8s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                      </circle>
                    </svg>
                  }
                  Register
              </button>
            }
          </Flex>
          <Flex justify='center' >
            <p className='terms-text'>
              By clicking on Register button you agree <br /><a href='/terms' target='_blank'>terms and conditions</a>.
          </p>
          </Flex>
        </WingBlank>
      </WingBlank>
    </Fragment>
  );
}

export default Register;