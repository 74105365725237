import React, { Fragment, useContext } from 'react';
import { Button, Flex, Card } from 'antd-mobile';
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { AppContext } from '../../../AppContext'
import '../style/index.css'

const CurrentPlan = (props) => {
  // TODO: always do one line break before function OR class start, render and return as well
  const { state } = useContext(AppContext)
  const user = state.currentUser
  const hasUpcomingPlan = user ? user.hasUpcomingPlan : false
  const plan = user && user.userSubscription && user.userSubscription.plans ? user.userSubscription.plans : null
  const endTime = user && user.userSubscription && user.userSubscription.endTime ? user.userSubscription.endTime : null
  const userPoints = user && user.points ? hasUpcomingPlan ? (user.points + user.userSubscription.lastOverDueAmount) : user.points : 0
  const userTopUpPoints = user && user.topUp ? user.topUp : 0

  return (
    <Fragment>
      {
        plan ?
          <Card className='current-plan-card'>
            <Card.Body className='current-plan-body'>
              <Fragment>
                <Flex justify='center' style={{ textAlign: 'center' }}>
                  <Flex.Item className='plan-flex'>
                    <div>
                      <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.4792 9.77085C24.767 9.77085 25 9.53789 25 9.25V5.08335C25 3.93433 24.0657 3 22.9167 3H2.08335C0.934326 3 0 3.93433 0 5.08335V9.25C0 9.53789 0.232959 9.77085 0.52085 9.77085C1.95669 9.77085 3.125 10.9392 3.125 12.375C3.125 13.8108 1.95669 14.9792 0.52085 14.9792C0.232959 14.9792 0 15.2121 0 15.5V19.6667C0 20.8157 0.934326 21.75 2.08335 21.75H22.9167C24.0657 21.75 25 20.8157 25 19.6667V15.5C25 15.2121 24.767 14.9792 24.4792 14.9792C23.0433 14.9792 21.875 13.8108 21.875 12.375C21.875 10.9392 23.0433 9.77085 24.4792 9.77085ZM20.8333 12.375C20.8333 14.2086 22.1939 15.7304 23.9583 15.9837V19.6667C23.9583 20.2409 23.4909 20.7083 22.9167 20.7083H2.08335C1.50913 20.7083 1.0417 20.2409 1.0417 19.6667V15.9837C2.8061 15.7304 4.1667 14.2086 4.1667 12.375C4.1667 10.5414 2.8061 9.01958 1.0417 8.76631V5.08335C1.0417 4.50913 1.50913 4.0417 2.08335 4.0417H22.9167C23.4909 4.0417 23.9583 4.50913 23.9583 5.08335V8.76631C22.1939 9.01958 20.8333 10.5414 20.8333 12.375Z" fill="#666666" />
                        <path d="M6.77085 4.95312C6.48296 4.95312 6.25 5.18608 6.25 5.47397V6.64585C6.25 6.93374 6.48296 7.1667 6.77085 7.1667C7.05874 7.1667 7.2917 6.93374 7.2917 6.64585V5.47397C7.29165 5.18608 7.05874 4.95312 6.77085 4.95312Z" fill="#666666" />
                        <path d="M6.77085 9.25C6.48296 9.25 6.25 9.48296 6.25 9.77085V10.8125C6.25 11.1004 6.48296 11.3334 6.77085 11.3334C7.05874 11.3334 7.2917 11.1004 7.2917 10.8125V9.77085C7.29165 9.48296 7.05874 9.25 6.77085 9.25Z" fill="#666666" />
                        <path d="M6.77085 13.4166C6.48296 13.4166 6.25 13.6496 6.25 13.9375V14.9791C6.25 15.267 6.48296 15.5 6.77085 15.5C7.05874 15.5 7.2917 15.267 7.2917 14.9791V13.9375C7.29165 13.6496 7.05874 13.4166 6.77085 13.4166Z" fill="#666666" />
                        <path d="M6.77085 17.5834C6.48296 17.5834 6.25 17.8163 6.25 18.1042V19.1459C6.25 19.4338 6.48296 19.6667 6.77085 19.6667C7.05874 19.6667 7.2917 19.4338 7.2917 19.1459V18.1042C7.29165 17.8163 7.05874 17.5834 6.77085 17.5834Z" fill="#666666" />
                        <path d="M17.4764 7.25416C17.2353 7.09445 16.9134 7.15905 16.7541 7.3986L10.5042 16.7736C10.3444 17.0131 10.409 17.3361 10.6486 17.4959C10.7376 17.5548 10.8378 17.5834 10.937 17.5834C11.1053 17.5834 11.2706 17.502 11.3708 17.3514L17.6208 7.97643C17.7806 7.73683 17.716 7.41388 17.4764 7.25416Z" fill="#666666" />
                        <path d="M13.5417 9.7709C13.5417 8.33506 12.6073 7.16675 11.4583 7.16675C10.3093 7.16675 9.375 8.33501 9.375 9.7709C9.375 11.2068 10.3093 12.375 11.4583 12.375C12.6074 12.375 13.5417 11.2067 13.5417 9.7709ZM10.4167 9.7709C10.4167 8.92402 10.8937 8.2084 11.4583 8.2084C12.0229 8.2084 12.5 8.92402 12.5 9.7709C12.5 10.6178 12.0229 11.3334 11.4583 11.3334C10.8937 11.3334 10.4167 10.6177 10.4167 9.7709Z" fill="#666666" />
                        <path d="M16.6666 12.375C15.5177 12.375 14.5833 13.5433 14.5833 14.9792C14.5833 16.415 15.5176 17.5833 16.6666 17.5833C17.8156 17.5833 18.75 16.415 18.75 14.9792C18.75 13.5433 17.8157 12.375 16.6666 12.375ZM16.6666 16.5417C16.102 16.5417 15.625 15.826 15.625 14.9792C15.625 14.1323 16.1021 13.4167 16.6666 13.4167C17.2312 13.4167 17.7083 14.1323 17.7083 14.9792C17.7083 15.826 17.2312 16.5417 16.6666 16.5417Z" fill="#666666" />
                      </svg>
                    </div>
                    <div className='name-header'>
                      {plan.name}
                    </div>
                    <div className='points'>
                      {userPoints}
                    </div>
                    <div className='description'>
                      Plan Points
                  </div>
                    <div className='expiry'>
                      Expires with Plan
                  </div>
                  </Flex.Item>
                  <Flex.Item>
                    <div>
                      <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7693 8.10547L11.2507 7.93135C12.4068 7.51304 13.1836 6.40566 13.1836 5.17578C13.1836 3.56035 11.8693 2.24609 10.2539 2.24609C9.02397 2.24609 7.9166 3.0229 7.49834 4.17905L6.5918 6.68491L5.68525 4.17905C5.26699 3.0229 4.15962 2.24609 2.92969 2.24609C1.31426 2.24609 0 3.56035 0 5.17578C0 6.40566 0.776758 7.51304 1.93296 7.93135L2.41431 8.10547H0V22.7539H25V8.10547H10.7693ZM8.87583 4.67734C9.08496 4.09932 9.63872 3.71094 10.2539 3.71094C11.0616 3.71094 11.7188 4.36807 11.7188 5.17578C11.7188 5.79092 11.3304 6.34473 10.7523 6.55386L7.81216 7.61753L8.87583 4.67734ZM1.46484 5.17578C1.46484 4.36807 2.12197 3.71094 2.92969 3.71094C3.54487 3.71094 4.09863 4.09932 4.30776 4.67739L5.37144 7.61753L2.4313 6.55386C1.85322 6.34473 1.46484 5.79092 1.46484 5.17578V5.17578ZM23.5352 21.2891H7.32422V19.8242H5.85938V21.2891H1.46484V9.57031H5.22324L3.05273 12.8261L4.27153 13.6387L5.85938 11.2569V13.9648H7.32422V11.2569L8.91211 13.6387L10.1309 12.8261L7.96035 9.57031H23.5352V21.2891Z" fill="#666666" />
                        <path d="M13.916 15.4297C15.1276 15.4297 16.1133 14.444 16.1133 13.2324C16.1133 12.0208 15.1276 11.0352 13.916 11.0352C12.7044 11.0352 11.7188 12.0208 11.7188 13.2324C11.7188 14.444 12.7044 15.4297 13.916 15.4297ZM13.916 12.5C14.3199 12.5 14.6484 12.8286 14.6484 13.2324C14.6484 13.6363 14.3199 13.9648 13.916 13.9648C13.5122 13.9648 13.1836 13.6363 13.1836 13.2324C13.1836 12.8286 13.5122 12.5 13.916 12.5Z" fill="#666666" />
                        <path d="M17.5781 17.627C17.5781 18.8385 18.5638 19.8242 19.7754 19.8242C20.987 19.8242 21.9727 18.8385 21.9727 17.627C21.9727 16.4154 20.987 15.4297 19.7754 15.4297C18.5638 15.4297 17.5781 16.4154 17.5781 17.627ZM20.5078 17.627C20.5078 18.0308 20.1792 18.3594 19.7754 18.3594C19.3715 18.3594 19.043 18.0308 19.043 17.627C19.043 17.2231 19.3715 16.8945 19.7754 16.8945C20.1792 16.8945 20.5078 17.2231 20.5078 17.627Z" fill="#666666" />
                        <path d="M13.3498 18.6371L19.212 11.3116L20.3561 12.2272L14.4938 19.5526L13.3498 18.6371Z" fill="#666666" />
                        <path d="M5.85938 15.4297H7.32422V18.3594H5.85938V15.4297Z" fill="#666666" />
                      </svg>
                    </div>
                    <div className='name-header'>Topup</div>
                    <div className='points'>
                      {userTopUpPoints}
                    </div>
                    <div className='description'>Topup Points</div>
                    <div className='expiry'>Never Expires</div>
                  </Flex.Item>
                </Flex>
              </Fragment>
            </Card.Body>
            <Card.Footer
              className='current-plan-footer'
              content={
                <Fragment>
                  {(moment(endTime).diff(moment(), 'days') <= 10) &&
                    <Button
                      size='small'
                      style={{ color: 'white', backgroundColor: '#aeca36', marginBottom: '10px', fontWeight: 'bold' }}
                      onClick={() => props.history.push('/plan')}>
                      RENEW PLAN
                  </Button>}
                  {plan &&
                    hasUpcomingPlan && user.userSubscription.lastOverDueAmount ?
                    <Fragment>
                      {user.userSubscription.lastOverDueAmount} points will expire on <b>{moment(user.userSubscription.lastOverDueDate).format('Do MMM YYYY')}</b>
                    </Fragment>
                    :
                    <Fragment>
                      Plan expires on <b>{moment(endTime).format('Do MMM YYYY')}</b> with remaining points
                    </Fragment>
                  }
                </Fragment>
              }
            />
            {
              (userPoints + userTopUpPoints) <= 500 && moment(endTime).diff(moment(), 'days') >= 10 &&
              <Card.Footer
                className='current-plan-footer top-up'
                content={"Low Balance"}
                extra={<Button size='small' onClick={() => props.history.push('/topup')}>TOPUP</Button>}
              />
            }
          </Card>
          :
          <Card className='current-plan-card no-plan'>
            <Card.Header
              title={`No Active Plan`}
              className='no-active-plan'
            />
            <Card.Footer
              className='current-plan-footer'
              content={
                <Button
                  size='small'
                  style={{ color: 'white', backgroundColor: '#aeca36', fontWeight: 'bold' }}
                  onClick={() => props.history.push('/plan')}>
                  CHOOSE PLAN
              </Button>
              }
            />
          </Card>
      }
    </Fragment>
  );
}

export default withRouter(CurrentPlan);