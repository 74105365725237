import React from 'react';
import { Card, Badge } from 'antd-mobile';

const PlanCard = (props) => {
  const { name, points, validity, price, renewAmount } = props
  const { onClick, selected, current } = props
  return (
    <Card onClick={onClick} className={`${selected ? 'active' : ''}`}>
      <Card.Header
        title={name}
        extra={current ? <Badge text="CURRENT PLAN" style={{ marginLeft: 12, padding: '1px 5px', backgroundColor: '#aeca36', borderRadius: 2, zIndex: 0 }} /> : null}
      />
      <Card.Body>
        <div className="card-body-header">Benefits:</div>
        <div>Total Points You'll Receive: <span className='highlight'>{points} Points</span></div>
        <div>Plan Validity: <span className='highlight'>{validity} Days</span></div>
        <div>Includes <span className='highlight'>4 day passes</span> for ikoverk</div>
      </Card.Body>
      <Card.Footer
        content={<div className='footer-content'><span className='price'><span style={{ fontSize: '1.1em' }}>₹ </span>
          {current && renewAmount ? <><span style={{ color: '#FF7171', textDecoration: 'line-through' }}>{price}</span> {renewAmount}</> : price}</span>
        </div>}
      />
    </Card>
  );
}

export default PlanCard;