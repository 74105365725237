import React, { useEffect, useState, Fragment } from 'react'
import { Button, WingBlank, InputItem, Flex, List, Toast, WhiteSpace } from 'antd-mobile'
import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie'
import { client } from '../../../apollo';
import { LOGIN, CREATE_USER } from '../graphql/Mutations';
import Meta from '../../../components/Meta'
import '../style/index.css'
import ikoverk from '../../../assets/lounge-logo.svg'
const cookies = new Cookies()

export default function OTP(props) {
  const [code, setCode] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!window.confirmationResult) {
      props.history.push({ pathname: "/" })
    }
  }, [props.history])

  useEffect(() => {
    const otpInput = document.getElementById('otpInput')
    if (otpInput) {
      setTimeout(() => {
        otpInput.focus()
      }, 1000)
    }
  }, [])

  function verifyCode() {
    setIsLoading(true)
    if (!code || code.length !== 6) {
      setIsLoading(false)
      Toast.info('Please enter phone number correctly!', 3, null, false)
      return
    }
    if (code) {
      window.confirmationResult.confirm(code).then(function (result) {
        const phone = result.user.phoneNumber.replace('+91', '')
        const isNewUser = result.additionalUserInfo.isNewUser
        if (isNewUser) {
          client.mutate({
            mutation: CREATE_USER,
            variables: { data: { phone } },
            fetchPolicy: 'no-cache'
          }).then(res => {
            if (res.data) {
              client.mutate({ mutation: LOGIN, variables: { phone, password: "test@123" } })
                .then(response => {
                  setIsLoading(false)
                  if (response.data.login && response.data.login.message) {
                    cookies.get("connect.sid")
                    const payload = jwt_decode(cookies.get("connect.sid"), { header: true })
                    cookies.set('token', payload.passport.user.token)
                    Toast.success('Logged In Successfully!', 3, null, false);
                    props.history.push('/')
                  } else {
                    Toast.fail('Login Failed!', 3, null, false)
                  }
                }).catch(error => {
                  setIsLoading(false)
                  console.log(error)
                  Toast.fail('Login Failed!', 3, null, false)
                })
            }
          }).catch(error => {
            setIsLoading(false)
            console.log(error)
            Toast.fail('User already exist!', 3, null, false)
          })
        } else {
          client.mutate({ mutation: LOGIN, variables: { phone, password: "test@123" } })
            .then(response => {
              setIsLoading(false)
              if (response.data.login && response.data.login.message) {
                cookies.get("connect.sid")
                const payload = jwt_decode(cookies.get("connect.sid"), { header: true })
                cookies.set('token', payload.passport.user.token, { maxAge: (48 * 60 * 1000) })
                Toast.success('Logged In Successfully!', 3, null, false);
                props.history.push('/')
              } else {
                Toast.fail('Login Failed!', 3, null, false)
              }
            }).catch(error => {
              setIsLoading(false)
              console.log(error)
              Toast.fail('Login Failed!', 3, null, false)
            })
        }
      }).catch(function (error) {
        setIsLoading(false)
        // User couldn't sign in (bad verification code?)
        Toast.fail('Wrong verification code', 3, null, false)
      });
    }
  }

  return (<Fragment>
    <Meta title='OTP' description='' />
    <Flex justify="center" style={{ paddingTop: '15vh' }}>
      <img src={ikoverk} alt='logo' width='60%' />
    </Flex>
    <Flex justify="center" style={{ paddingTop: '10vh', color: 'white' }}>
      <h2 style={{ fontWeight: '500' }}>WELCOME!!</h2>
    </Flex>
    <WingBlank>
      <WingBlank>
        <List renderHeader={() => 'Enter OTP'} className='input-list'>
          <Flex>
            <Flex.Item>
              <InputItem
                type='phone'
                maxLength='6'
                onChange={(e) => setCode(e)}
                id='otpInput'
              />
            </Flex.Item>
          </Flex>
        </List>
      </WingBlank>
    </WingBlank>
    <Flex justify="center" style={{ paddingTop: '10vh' }}>
      <Button icon={isLoading ?
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ shapeRendering: 'auto' }} width="30px" height="30px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" fill="none" stroke="#aeca36" strokeWidth="5" r="30" strokeDasharray="141.37166941154067 49.12388980384689" transform="rotate(2.84985 50 50)">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.8s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
          </circle>
        </svg> : null
      }
        className={`btn-inverted ${isLoading ? 'btn-disabled' : ''}`} onClick={(e) => verifyCode(e)} >Verify</Button>
    </Flex>
    <WhiteSpace size='xl' />
  </Fragment>);
}